export const MenuItems2 = [
    {
      title: 'JW3',
      path: '/gallery2',
      className: 'dropdown-link'
    },
    {
      title: 'LONDON PREMIERE',
      path: '/gallery',
      className: 'dropdown-link'
    },
  ];